import React from 'react'

import cn from 'classnames'

import classes from './raf.module.scss'
import { Content } from './ReferAFriend.content'
import {
  Angle,
  Icon,
  Para,
  RafContainer,
  RafWrapper,
  Title,
} from './ReferAFriend.styles'
import ReferAFriendCta from './ReferAFriendCta'
import { triggerCustomModuleLoadEvent } from '../../utils/dataLayer'

const fireDmpgLoadEvent = () => {
  triggerCustomModuleLoadEvent([
    {
      id: 'friend-referral',
      name: 'friend-referral-offer',
      placement: 'home-hero-section',
      items: [
        {
          id: 'referral-banner-1',
          type: 'tool',
        },
      ],
    },
  ])
}

export class ReferAFriendHeroCard extends React.Component<{
  buyapowa_share_url?: string
}> {
  state = {
    dmpgLoadEventHasFired: false,
  }

  componentDidMount() {
    this.runInitialProcesses()
  }

  componentDidUpdate() {
    this.runInitialProcesses()
  }

  input: any = null

  runInitialProcesses = () => {
    const { dmpgLoadEventHasFired } = this.state
    const { buyapowa_share_url } = this.props

    if (!dmpgLoadEventHasFired && buyapowa_share_url) {
      fireDmpgLoadEvent()
      this.setState({ dmpgLoadEventHasFired: true })
    }
  }

  copyRafLink = () => {
    if (!this.input) return
    this.input.select()
    document.execCommand('copy')
  }

  returnAppendedRafLink = (buyapowaUrl) => {
    const lastCharacter = buyapowaUrl.substr(buyapowaUrl.length - 1) === 'x'
    return lastCharacter
      ? buyapowaUrl.replace(/.$/, 'p')
      : buyapowaUrl.concat('p')
  }

  render() {
    const { buyapowa_share_url } = this.props
    if (!buyapowa_share_url) return null
    const appendedLink = this.returnAppendedRafLink(buyapowa_share_url)
    return (
      <div className={classes.root}>
        <div className={cn('redesign-container', classes.container)}>
          <RafContainer data-testid={Content.id} data-type="dismissible-card">
            <RafWrapper>
              <Title data-testid={`${Content.id}-title`}>{Content.title}</Title>
              <Para data-testid={`${Content.id}-text`}>{Content.text}</Para>
              <ReferAFriendCta buyapowaShareUrl={appendedLink} />
            </RafWrapper>
            <Angle>
              <Icon
                src={Content.icon}
                data-testid="raf-hero-banner-icon"
                alt={'A voucher icon'}
              />
            </Angle>
          </RafContainer>
        </div>
      </div>
    )
  }
}
