import React, { FC } from 'react'

import { useLocation } from '@reach/router'
import cn from 'classnames'
import { navigate } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Swiper as SwiperClass, SwiperOptions } from 'swiper/types'

import classes from './smarty-tabs.module.scss'

const removeStyleAttr = (swiper: SwiperClass) => {
  if (!swiper.params.enabled) {
    swiper.wrapperEl.removeAttribute('style')
    swiper.slides.forEach((slide) => slide.removeAttribute('style'))
  }
}
const options: SwiperOptions = {
  spaceBetween: 16,
  breakpoints: {
    0: {
      enabled: true,
      slidesPerView: 'auto',
      centeredSlides: false,
    },
    760: {
      enabled: false,
    },
  },
}

interface SmartyTabsProps {
  tabs: {
    hash: string
    content: string
    itemName: string
  }[]
}

export const SmartyTabs: FC<SmartyTabsProps> = ({ tabs }) => {
  // tab-hash-2-index, index-2-tab-hash mapping
  // used for smooth integration between slider, tabs, and URL
  const mapping = tabs.reduce(
    (acc, tab, i) => ({ ...acc, [tab.hash]: i, [i]: tab.hash }),
    {}
  )
  const { hash } = useLocation()
  const activeIndex = hash in mapping ? mapping[hash] : 0
  const handleTabClick = async (index: number) => await navigate(mapping[index])

  return (
    <div className={classes.root}>
      <Swiper
        runCallbacksOnInit={false}
        onInit={removeStyleAttr}
        onResize={removeStyleAttr}
        initialSlide={activeIndex}
        {...options}
      >
        {tabs.map((tab, index) => (
          <SwiperSlide key={tab.hash}>
            <div
              onClick={() => handleTabClick(index)}
              className={cn(
                classes.pill,
                mapping[activeIndex] === tab.hash && classes.pillActive
              )}
              data-item-category="GI"
              data-item-name={tab.itemName}
            >
              {tab.content}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
