import { isWindowExist } from '@smarty-nx/code-utils'

import { RootState } from './rootReducer'
import { CONFIG, FEATURES } from '../config'

const getDashboardState = () => {
  let state = null
  if (isWindowExist()) {
    const data = window.sessionStorage.getItem('state')
    state = data && JSON.parse(data)
    if (!state) {
      const data = window.localStorage.getItem('state')
      state = data && JSON.parse(data)
    }
  }
  return state
}

export const loadState = () => {
  try {
    const serializedSharedState = localStorage.getItem('smartySharedData')

    if (!serializedSharedState) {
      return { dashboard: { data: getDashboardState() } }
    }

    const parsedSharedState = JSON.parse(serializedSharedState)
    return {
      ...parsedSharedState,
      dashboard: { data: getDashboardState() },
    }
  } catch (err) {
    return undefined
  }
}

export const saveState = (state: RootState) => {
  const { smartySharedData, dashboard } = state
  try {
    const sharedStateToSave = {
      smartySharedData,
    }
    const serializedSharedState = JSON.stringify(sharedStateToSave)

    // Updating auth for dashboard part in case JWT token has been refreshed on marketing
    const currentStoredState = localStorage.getItem('state')
    if (currentStoredState && FEATURES.REFRESH_TOKEN) {
      const appState = JSON.parse(currentStoredState)
      const oldExpiry = appState.auth.expiry
      const newExpiry = dashboard.data?.auth.expiry

      if (oldExpiry && newExpiry && newExpiry > oldExpiry) {
        const updatedState = JSON.stringify({
          ...appState,
          auth: dashboard.data?.auth,
        })
        localStorage.setItem('state', updatedState)

        if (CONFIG.SMARTY_ENV === 'localhost') {
          window.frames.dashboard?.contentWindow?.postMessage(
            {
              smarty: {
                state: updatedState,
              },
            },
            CONFIG.DASHBOARD_URL
          )
        }
      }
    }

    localStorage.setItem('smartySharedData', serializedSharedState)
  } catch (err) {
    // Ignore write errors for now. Log them when we set that up.
  }
}
