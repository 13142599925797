import { useState, useEffect } from 'react'

import { debounce } from '../utils/debounce'
import { isWindowExist } from '../utils/safeWindow'

interface WindowDimensions {
  width: number | undefined
  height: number | undefined
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowDimensions>({
    width: isWindowExist() ? window.innerWidth : undefined,
    height: isWindowExist() ? window.innerHeight : undefined,
  })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    const debounceHandleResize = debounce(handleResize, 500)
    window.addEventListener('resize', debounceHandleResize)
    handleResize()

    return () => window.removeEventListener('resize', debounceHandleResize)
  }, [])

  return windowSize
}
