import React, { FC } from 'react'

import cn from 'classnames'

import classes from './info-footer.module.scss'
import { Typography } from '../typography/typography'

interface InfoFooterProps {
  description?: string
  linkLabel?: string
  src?: string
  className?: string
}

export const InfoFooter: FC<InfoFooterProps> = ({
  description,
  linkLabel,
  src,
  className,
}) => {
  return (
    <Typography
      className={cn(classes.root, className)}
      variant="Kollektif/ExtraSmall"
      component="div"
    >
      {description && <p className={classes.description}>{description}</p>}
      {linkLabel && src && (
        <a
          className={classes.cta}
          href={src}
          target="_blank"
          rel="noopener noreferrer"
        >
          {linkLabel}
        </a>
      )}
    </Typography>
  )
}
