import React, { FC, useEffect } from 'react'

import { priceToString } from '@smarty-nx/code-utils'
import { Price } from '@smarty-nx/data'
import { colors } from '@smarty-nx/ds'
import { graphql, PageProps } from 'gatsby'

import { planDetailsContent } from './content'
import {
  renderAllPlansInclude,
  renderCoverage,
  renderDiscountCard,
  renderLogoGarden,
  renderQuote,
  renderTimeline,
  renderUnlimitedBanner,
  renderWhySmarty,
} from './renderComponents'
import { PageSection, PayPalWrapper, PlanHeading, SiteWrapper } from './styles'
import Button from '../../components/Button'
import CountdownBanner from '../../components/CountdownBanner'
import { PlanDetailsBanner } from '../../components/PlanDetailsBanner/PlanDetailsBanner'
import PlanSection2 from '../../components/PlanSectionPhase2'
import SEO from '../../components/Seo'
import BlockContainer from '../../components/styles/BlockContainer'
import { Centered } from '../../components/styles/Position'
import SiteContainer from '../../components/styles/SiteContainer'
import Spacer from '../../components/styles/Spacer'
import TrustPilot from '../../components/TrustPilot'
import withApp, { AppProps } from '../../components/withApp/withApp'
import { FEATURES } from '../../config'
import {
  RICH_DATA_STRINGS,
  TRACKING_CATEGORIES,
} from '../../constants/globalStrings'
import FaqSection from '../../modular-system/FaqSection'
import { productDmpgAttributes } from '../../utils/dmpgAttributes'
import { voucherWithCountdown } from '../../utils/getVoucher'
import { mapV1ToV3, matchPlanMeta } from '../../utils/plans'

const noIndex = [{ name: 'robots', content: 'noindex, nofollow' }]

type PlanDetailsProps = AppProps &
  PageProps<Queries.PlanPageQuery, { slug: string }>

const getPriceInDigits = (price: Price) => {
  const formattedPrice = priceToString(price)
  return formattedPrice.substring(1)
}

const PlanDetails: FC<PlanDetailsProps> = (props) => {
  const {
    voucherCode,
    pageContext: { slug },
    plans: { plans: originalPlans },
    groupPlans,
    fetchGroupPlans,
    data: { faq },
  } = props

  const plans = (voucherCode ? originalPlans : groupPlans?.plans) || []
  const { priceData, planMeta: plan } = matchPlanMeta(plans, slug)
  const voucher = priceData ? priceData.voucher || null : null
  const isDataOnly = plan.plan_is_data_only

  useEffect(() => {
    fetchGroupPlans()
  }, [fetchGroupPlans])

  const reusableJsonProduct = {
    '@context': 'http://schema.org',
    '@type': 'Product',
    name: plan.name,
    url: `https://smarty.co.uk/plans/${plan.slug}`,
    brand: {
      '@type': 'Organization',
      name: 'SMARTY',
    },
  }

  let jsonLdProduct

  if (voucher && voucher.plan_card_heading) {
    jsonLdProduct = {
      ...reusableJsonProduct,
      description: voucher.plan_card_heading,
      offers: {
        '@type': 'Offer',
        priceCurrency: 'GBP',
        price: getPriceInDigits(priceData.finalPriceWithoutCredit),
        seller: {
          '@type': 'Organization',
          name: 'SMARTY',
        },
      },
    }
  } else if (
    priceData &&
    priceData.description &&
    priceData.originalPrice.value
  ) {
    jsonLdProduct = {
      ...reusableJsonProduct,
      description: priceData.description,
      offers: {
        '@type': 'Offer',
        priceCurrency: 'GBP',
        price: getPriceInDigits(priceData.originalPrice),
        seller: {
          '@type': 'Organization',
          name: 'SMARTY',
        },
      },
    }
  }

  const jsonLdOrganization = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'SMARTY',
    description:
      'SMARTY is a new SIM-only mobile network that’s built to be fair, transparent and smart',
    url: 'https://smarty.co.uk',
  }

  const countdown = voucherWithCountdown(
    FEATURES.Q4_WINTER_SALE ? plans : plans.map(mapV1ToV3)
  )

  return (
    <SiteWrapper
      {...productDmpgAttributes(plan, priceData)}
      data-module-id={plans.length > 0 ? `pdp-${plan.slug}` : null}
      data-module_name="plans-selection"
      data-module_placement="pdp-other-plans-section"
      data-product-details_id={plan.slug}
    >
      <SEO
        title={plan.title}
        description={plan.description}
        preventTitleTemplate
        additionalMeta={slug === 'mini' ? noIndex : undefined}
        itemType={RICH_DATA_STRINGS.FAQ_ITEM_TYPE}
      >
        {jsonLdProduct && (
          <script type="application/ld+json">
            {JSON.stringify(jsonLdProduct)}
          </script>
        )}

        <script type="application/ld+json">
          {JSON.stringify(jsonLdOrganization)}
        </script>
      </SEO>
      {countdown && countdown.planSlug === plan.slug && (
        <CountdownBanner
          planName={countdown.planName}
          planSlug={countdown.planSlug}
          voucherExpiryDate={countdown.voucherExpiryDate}
          discountedPrice={countdown.discountedPrice}
          originalPrice={countdown.originalPrice}
        />
      )}

      <SiteContainer>
        <PageSection>
          <PlanHeading center data-testid="plan-page-heading">
            Commitment-free SIM plan
          </PlanHeading>

          <div role="region" aria-live="polite">
            <PlanDetailsBanner
              planMeta={plan}
              priceData={priceData}
              buyBtnTitle={isDataOnly ? `Get the ${plan.data} plan` : undefined}
            />
          </div>

          <Centered style={{ maxWidth: '384px' }} margin="40px auto 16px">
            <Button
              href={`/plans/${slug}#plans`}
              data-testid="view-all-plans-link"
              linkStyle
              foreColor="#fff"
              customStyle={{
                backgroundColor: colors['UI/DarkGrey'],
              }}
              /* wide - 384px max desktop */
              wide
              data-item-category={TRACKING_CATEGORIES.NAVIGATION}
              data-item-name={planDetailsContent.ctaViewAllDataName}
              to={undefined}
              color={undefined}
              onClick={undefined}
              ariaLabel={undefined}
              fullWidth={undefined}
              centered={undefined}
              newTab={undefined}
              className={undefined}
              eventName={undefined}
              eventProductId={undefined}
              eventCheckoutType={undefined}
              custStyle={undefined}
            >
              {planDetailsContent.ctaViewAll}
            </Button>
          </Centered>

          {renderAllPlansInclude(plan)}
        </PageSection>

        <PayPalWrapper>
          <img
            src="/assets/images/third-party/accepting-paypal.png"
            alt="Now accepting PayPal"
          />
        </PayPalWrapper>
        <TrustPilot />
        {renderTimeline(plan)}
      </SiteContainer>

      {slug === 'unlimited' && renderUnlimitedBanner()}

      {plan.data_discount && renderDiscountCard()}

      {renderQuote()}
      {renderCoverage()}
      {renderWhySmarty()}
      {renderLogoGarden()}

      <Spacer />

      <BlockContainer
        style={{
          backgroundColor: colors['UI/DarkGrey'],
        }}
      >
        <SiteContainer>
          <PlanSection2 currentPlan={plan} plans={plans} />
        </SiteContainer>
      </BlockContainer>

      <SiteContainer>
        <Spacer />
        {faq && <FaqSection {...faq} />}
        <Spacer />
      </SiteContainer>
    </SiteWrapper>
  )
}

export default withApp(PlanDetails)

export const query = graphql`
  query PlanPage {
    faq: contentfulPageSection(sectionName: { eq: "default-faq-list" }) {
      ...FaqSection
    }
  }
`
