import React from 'react'

import { getBannerByType } from '@smarty-nx/contentful'
import { ContentfulProcessor } from '@smarty-nx/ds'

import VoucherIcon from './../../static/images/raf/voucher.svg'

const RafLinkBanner = getBannerByType('refer-a-friend-link-banner')

export const Content = {
  id: 'raf-hero-banner',
  title: RafLinkBanner?.title,
  text: <ContentfulProcessor content={RafLinkBanner?.description || ''} />,
  icon: VoucherIcon,
  cta: 'Copy referral link',
}
