import { useEffect, useState } from 'react'

import { isWindowExist } from '../utils/safeWindow'

export const useInMediaRange = (min = 0, max = 759) => {
  const [isMobile, setIsMobile] = useState(() => {
    return isWindowExist()
      ? window.innerWidth >= min && window.innerWidth <= max
      : false
  })

  useEffect(() => {
    const mediaWatcher = window.matchMedia(
      `screen and (min-width: ${min}px) and (max-width: ${max}px)`
    )

    setIsMobile(mediaWatcher.matches)

    function update(e: MediaQueryListEvent) {
      setIsMobile(e.matches)
    }

    mediaWatcher.addEventListener('change', update)

    return function cleanup() {
      mediaWatcher.removeEventListener('change', update)
    }
  }, [min, max])

  return isMobile
}
