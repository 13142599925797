import React, { FC } from 'react'

import { RaeBanner } from '@smarty-nx/ds'
import { graphql, PageProps } from 'gatsby'

import CountdownBanner from '../components/CountdownBanner'
import { RafBanner } from '../components/FriendReferralBanner/RafBanner'
import PageHead from '../components/PageHead'
import { ReferAFriendHeroCard } from '../components/ReferAFriendHeroCard/ReferAFriendHeroCard'
import SiteContainer from '../components/styles/SiteContainer'
import withApp, { AppProps } from '../components/withApp/withApp'
import { CONFIG, FEATURES } from '../config'
import { RICH_DATA_STRINGS } from '../constants/globalStrings'
import { useIsAuthenticated, useOnetrust, useReferralLink } from '../hooks'
import {
  AppDownloadSection,
  AwardsSection,
  HeroBanner,
  PopularPlansSection,
  ReasonsToChooseSection,
  TrustPilotSection,
  WhatWeOfferSection,
} from '../modules/page-section'
import { voucherWithCountdown } from '../utils/getVoucher'
import { mapV1ToV3 } from '../utils/plans'

type IndexPageProps = AppProps & PageProps<Queries.IndexPageQuery>

const IndexPage: FC<IndexPageProps> = ({
  plans: { plans },
  data: { awardsSection },
}) => {
  const isAuthenticated = useIsAuthenticated()
  const referralLink = useReferralLink()
  const countdown = voucherWithCountdown(
    FEATURES.Q4_WINTER_SALE ? plans : plans.map(mapV1ToV3)
  )

  useOnetrust()

  return (
    <>
      {countdown && (
        <CountdownBanner
          planName={countdown.planName}
          planSlug={countdown.planSlug}
          voucherExpiryDate={countdown.voucherExpiryDate}
          discountedPrice={countdown.discountedPrice}
          originalPrice={countdown.originalPrice}
        />
      )}
      <ReferAFriendHeroCard buyapowa_share_url={referralLink} />
      {FEATURES.REFER_AND_EARN && !isAuthenticated && <RaeBanner opened />}
      {!FEATURES.REFER_AND_EARN && <RafBanner />}
      <HeroBanner />
      <WhatWeOfferSection />
      <ReasonsToChooseSection />
      <TrustPilotSection transparent />
      <PopularPlansSection />
      {awardsSection && <AwardsSection {...awardsSection} />}
      <AppDownloadSection />

      {CONFIG.DEBUG && (
        <SiteContainer>
          <div>
            <p style={{ color: '#999999' }}>Features: </p>
            <pre style={{ color: '#999999' }}>
              {JSON.stringify(FEATURES, null, 2)}
            </pre>
          </div>
          <div>
            <p style={{ color: '#999999' }}>Config: </p>
            <pre style={{ color: '#999999' }}>
              {JSON.stringify(CONFIG, null, 2)}
            </pre>
          </div>
        </SiteContainer>
      )}
    </>
  )
}

const indexPageContent = {
  seo: {
    title: 'Simple, honest mobile',
  },
}

export default withApp(IndexPage)

export const Head = () => (
  <PageHead
    title={indexPageContent.seo.title}
    itemType={RICH_DATA_STRINGS.FAQ_ITEM_TYPE}
  />
)

export const query = graphql`
  query IndexPage {
    awardsSection: contentfulPageSection(sectionName: { eq: "awards" }) {
      ...GenericPageSection
    }
  }
`
