import banners from '../../data/genericBanners.json'
import { genericBannerNames } from '../constants/contentfulTypes'

export interface GenericBannerItem {
  title: string
  type: string
  description?: string
  image?: {
    title: string
    url: string
  }
  link?: string
}

export const getBannerByType = (bannerType: string) => {
  if (typeof banners === 'undefined') {
    return null
  } else {
    return banners.find((item) => item.type === bannerType)
  }
}

export const getAskCommunityBanner = () =>
  getBannerByType(genericBannerNames.ASK_COMMUNITY)

export const getHelpCentreBanner = () =>
  getBannerByType(genericBannerNames.HELP_CENTRE)

export const getAppDownloadBanner = () =>
  getBannerByType(genericBannerNames.TRY_OUR_APP)

export const getChristmasOpeningHoursBanner = () =>
  getBannerByType(genericBannerNames.CHRISTMAS_OPENING_HOURS)

export const getPlanBenefitsBanner = () =>
  getBannerByType(genericBannerNames.PLAN_BENEFITS)

export const getPlanQuestionsBanner = () =>
  getBannerByType(genericBannerNames.FP_QUESTIONS)

export const getWhatYouCanDoBanner = () =>
  getBannerByType(genericBannerNames.FP_WHAT_YOU_CAN_DO)

export const getTheOwnerBanner = () =>
  getBannerByType(genericBannerNames.FP_THE_OWNER)

export const getTheMembersBanner = () =>
  getBannerByType(genericBannerNames.FP_THE_MEMBERS)

export const getWelcomeToGroupNotificationBanner = () =>
  getBannerByType(genericBannerNames.FP_WELCOME_TO_GROUP)

export const getWelcomeToSmartyNotificationBanner = () =>
  getBannerByType(genericBannerNames.FP_WELCOME_TO_SMARTY)

export const getInviteRejectedBanner = () =>
  getBannerByType(genericBannerNames.FP_INVITE_REJECTED)

export const getSoloPlansBanner = () =>
  getBannerByType(genericBannerNames.FP_SOLO_PLANS)

export const getInvitationSentBanner = () =>
  getBannerByType(genericBannerNames.INVITATION_SENT_IMAGE)

export const getRafPersonalisedBanner = () =>
  getBannerByType(genericBannerNames.RAF_BANNER)

export const getRaeBannerByReferralLink = () =>
  getBannerByType(genericBannerNames.RAE_BANNER_BY_REFERRAL_LINK)

export const getRaeBannerByBonusSim = () =>
  getBannerByType(genericBannerNames.RAE_BANNER_BY_BONUS_SIM)

export const getRaeBannerSendSimByPost = () =>
  getBannerByType(genericBannerNames.RAE_BANNER_SEND_BY_POST)

export const getRaeBannerFreeSimByPost = () =>
  getBannerByType(genericBannerNames.RAE_BANNER_A_FREE_SIM_BY_POST)

export const getInvalidInvitationBanner = () =>
  getBannerByType(genericBannerNames.INVALID_INVITATION_IMAGE)

export const getSwitchToSmartyBanner = () =>
  getBannerByType(genericBannerNames.SWITCH_TO_SMARTY)

export const getEveryoneUnlimited = () =>
  getBannerByType(genericBannerNames.EVERYONE_UNLIMITED)

export const getActivateYourSimFirst = () =>
  getBannerByType(genericBannerNames.ACTIVATE_YOUR_SIM_FIRST)

export const getRequestActivationBanner = () =>
  getBannerByType(genericBannerNames.FP_REQUEST_ACTIVATION)

export const getActivationFailedBanner = () =>
  getBannerByType(genericBannerNames.FP_ACTIVATION_FAILED)

export const getActivateNowOwnerBanner = () =>
  getBannerByType(genericBannerNames.FP_ACTIVATE_NOW_OWNER)

export const getActivateNowMemberBanner = () =>
  getBannerByType(genericBannerNames.FP_ACTIVATE_NOW_MEMBER)

export const getSwitchingYourServicesWarningBanner = () =>
  getBannerByType(genericBannerNames.SWITCHING_YOUR_SERVICES_WARNING)

export const getWhatIsStacBanner = () =>
  getBannerByType(genericBannerNames.FP_WHAT_IS_STAC)

export const getWhatIsPacBanner = () =>
  getBannerByType(genericBannerNames.FP_WHAT_IS_PAC)

export const getGotYourDetailsWhatNextBanner = () =>
  getBannerByType(genericBannerNames.GOT_YOUR_DETAILS_WHAT_NEXT)

export const getInvalidAccountBanner = () =>
  getBannerByType(genericBannerNames.FP_INVALID_ACCOUNT)

export const getMemberInvitationSentBanner = () =>
  getBannerByType(genericBannerNames.FP_MEMBER_INVITATION_SENT)

export const getMemberInvitationAcceptedBanner = () =>
  getBannerByType(genericBannerNames.FP_MEMBER_INVITATION_ACCEPTED)

export const getMemberInvitationRejectedBanner = () =>
  getBannerByType(genericBannerNames.FP_MEMBER_INVITATION_REJECTED)

export const getActivationPendingBanner = () =>
  getBannerByType(genericBannerNames.FP_ACTIVATION_PENDING)

export const getMemberActivateSimBanner = () =>
  getBannerByType(genericBannerNames.FP_MEMBER_ACTIVATE_SIM)

export const getAwaitingActivationBanner = () =>
  getBannerByType(genericBannerNames.FP_AWAITING_ACTIVATION)
