export const countdownBannerContent = {
  // TIME: is the key for accessing the time duration from the imported component from react-countdown
  // TEXT TO DISPLAY: the string we want to display to the user
  durations: [
    {
      time: 'days',
      text_to_display: 'Days',
    },
    {
      time: 'hours',
      text_to_display: 'Hours',
    },
    {
      time: 'minutes',
      text_to_display: 'Minutes',
    },
    {
      time: 'seconds',
      text_to_display: 'Seconds',
    },
  ],
}
