import React, { ReactNode, FC, useState } from 'react'

import cn from 'classnames'

import classes from './accordion.module.scss'
import { Icon } from '../icon/icon'

/**
 * Design - https://www.figma.com/design/RE9n7PIwHG7A7WHA7kEVhN/WEB-Components?node-id=1249-30348&t=WmVMoPS7YzIIk9pg-0
 * Inset shaded type - https://www.figma.com/design/w0fCpG0jR7ziOvFxyOZvt8/ADR?node-id=34-303&t=AtOrBOoEFkr9ih9H-0
 * */

interface AccordionProps {
  title: string
  type?: 'full-width' | 'inset' | 'inset-shaded'
  children?: ReactNode
  dataTestId?: string
  className?: string
}

export const Accordion: FC<AccordionProps> = ({
  title,
  type = 'full-width',
  children,
  dataTestId,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const isInset = type === 'inset' || type === 'inset-shaded'
  const isInsetShaded = type === 'inset-shaded'

  return (
    <div
      data-test={dataTestId}
      className={cn(classes.accordion, className, isInset && classes.inset)}
    >
      <div onClick={() => setIsOpen(!isOpen)} className={classes.header}>
        <p className={classes.title}>{title}</p>
        <Icon
          className={cn(classes.chevron, {
            [classes.closed]: !isOpen,
            [classes.inset]: isInset,
          })}
          name="chevron-up"
        />
      </div>
      {isOpen && (
        <div className={cn(classes.content, isInset && classes.inset)}>
          {children}
        </div>
      )}
      {isInsetShaded && !isOpen && (
        <div
          className={cn(classes.content, classes.inset, classes.insetShaded)}
        >
          <div className={classes.shade} />
          {children}
        </div>
      )}
    </div>
  )
}
