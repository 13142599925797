import dayjs from 'dayjs'

const DEFAULT_COUNTDOWN_TIMER_IN_DAYS = 7

export const isVoucherValid = (voucher) => {
  const now = Date.now()
  const from = new Date(voucher.valid_from)
  const to = new Date(voucher.valid_to)
  return from < now && to > now
}

const isCountdownValid = (voucher) => {
  const countdownDuration =
    voucher.countdown_timer_in_days || DEFAULT_COUNTDOWN_TIMER_IN_DAYS
  const countdownStartDate = dayjs(voucher.valid_to).subtract(
    countdownDuration,
    'day'
  )
  const isValid =
    voucher.is_default &&
    voucher.show_countdown &&
    isVoucherValid(voucher) &&
    countdownStartDate < Date.now()

  return isValid
}

export const voucherWithCountdown = (plans) => {
  let detailsForCountdown = {}
  const plansWithCountdowns = plans.filter(
    (plan) => plan.voucher && isCountdownValid(plan.voucher)
  )

  if (plansWithCountdowns.length >= 1) {
    const plan = plansWithCountdowns[0]
    detailsForCountdown = {
      planName: plan.name,
      planSlug: plan.slug,
      voucherExpiryDate: plan.voucher.valid_to,
      discountedPrice: plan.finalPrice.value,
      originalPrice: plan.dataAllowanceGB,
    }
  }

  // We only return the voucher details object if present
  if (Object.keys(detailsForCountdown).length !== 0) {
    return detailsForCountdown
  }
  return false
}
