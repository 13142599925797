import React from 'react'

import { isWindowExist } from '@smarty-nx/code-utils'

import DataOnlySimPlan from './data-only-sim-plan'
import { CONFIG } from '../../../config'
import { useIsAuthenticated } from '../../../hooks'

const getExistingQueryParams = (search) => {
  if (search.length) {
    return `&${search.substring(1)}`
  }
  return ''
}

/**
 * Authenticate the user and navigates to Data Only Sim Landing Page else redirect to Login page
 */
const DataOnlyPlans = () => {
  const isAuthenticated = useIsAuthenticated()

  if (isAuthenticated) {
    return <DataOnlySimPlan />
  }
  if (isWindowExist()) {
    window.location.replace(
      `${
        CONFIG.DASHBOARD_URL
      }/login?redirect=/dashboard/groups/data-only-plans${getExistingQueryParams(
        window.location.search
      )}`
    )
  }
  return null
}

export default DataOnlyPlans
