import React, { FC } from 'react'

import {
  getAllPlansPageCarouselSection,
  getPlansSlugs,
  planCollectionNames,
} from '@smarty-nx/contentful'
import cn from 'classnames'

import {
  DataDiscountInfoModal,
  DataOnlyInfoModal,
  VoiceInfoModal,
} from './_parts'
import classes from './plan-batches.module.scss'
import { usePlans } from '../../../hooks'
import { safeLocation } from '../../../utils/safeWindow'
import { PlansCarousel, PlanTilesBatch, SmartyTabs } from '../../components'

const VOICE_PLAN_SLUGS = getPlansSlugs(planCollectionNames.VOICE_PLANS)
const DATA_ONLY_PLAN_SLUGS = getPlansSlugs(planCollectionNames.DATA_ONLY_PLANS)
const MONEY_BACK_PLAN_SLUGS = getPlansSlugs(
  planCollectionNames.MONEY_BACK_PLANS
)

const tabs = [
  {
    hash: '#all',
    content: 'All plans',
    itemName: 'all_plans_tab',
  },
  {
    hash: '#classic',
    content: 'Voice plans',
    itemName: 'voice_tab',
  },
  {
    hash: '#data-only',
    content: 'Data Only plans',
    itemName: 'data_only_tab',
  },
  {
    hash: '#data-discount',
    content: 'Data Discount plans',
    itemName: 'data_discount_tab',
  },
]

const section = getAllPlansPageCarouselSection()

const defaultPosition =
  section && section.selectedItem
    ? section.items.findIndex((item) => item.slug === section.selectedItem.slug)
    : 0

export const PlanBatchesSection: FC = () => {
  const { list: voicePlans } = usePlans(VOICE_PLAN_SLUGS)
  const { list: dataOnlyPlans } = usePlans(DATA_ONLY_PLAN_SLUGS)
  const { list: moneyBackPlans } = usePlans(MONEY_BACK_PLAN_SLUGS)

  const location = safeLocation()
  const filter = location.hash?.replace('#', '')
  const slugs = section?.items.map((item) => item.slug)

  return (
    <div className={classes.root}>
      {/* TABS does not work, copied to have good UI */}
      <SmartyTabs tabs={tabs} />

      {section && slugs && (filter === 'all' || filter === '') && (
        <div
          data-module-id="current-offers"
          data-module_name="current_offers"
          data-module_placement="plan-list"
        >
          <PlansCarousel
            title={section.title}
            slugs={slugs}
            heading={<div className={classes.spacer} />}
            dataTestId="current-offers"
            moduleId="current-offers"
            defaultPosition={defaultPosition}
          />
        </div>
      )}

      <div className={cn('redesign-container', classes.container)}>
        {(filter === 'classic' || filter === 'all' || filter === '') && (
          <PlanTilesBatch
            dataTestId="voice-plans"
            moduleId="voice-plans"
            moduleName="voice_plans"
            title="Voice plans"
            plans={voicePlans}
            subtitle="Unlimited calls & texts"
            info={<VoiceInfoModal />}
            className={cn(classes.plans, classes.noMarginOnMobile)}
          />
        )}

        {(filter === 'data-only' || filter === 'all' || filter === '') && (
          <PlanTilesBatch
            dataTestId="data-only-plans"
            moduleId="data-only-plans"
            moduleName="data_only_plans"
            title="Data Only plans"
            plans={dataOnlyPlans}
            subtitle="Just great value data"
            info={<DataOnlyInfoModal />}
            className={classes.plans}
          />
        )}

        {(filter === 'data-discount' || filter === 'all' || filter === '') && (
          <PlanTilesBatch
            dataTestId="data-discount-plans"
            moduleId="data-discount-plans"
            moduleName="data_discount_plans"
            title="Data Discount plans"
            plans={moneyBackPlans}
            subtitle="Get money back for unused data"
            info={<DataDiscountInfoModal />}
            className={classes.plans}
          />
        )}
      </div>
    </div>
  )
}
