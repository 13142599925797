import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum'

interface InitProps {
  applicationId: string
  clientToken: string
  env: string
  site: string | undefined
  service: string
  commit: string
  sessionReplay?: boolean | undefined
  sessionReplaySampleRate?: number | undefined
  defaultPrivacyLevel?: DefaultPrivacyLevel
  beforeSend?: (event: Event) => boolean
}

export const initDatadogRum = ({
  applicationId,
  clientToken,
  site,
  service,
  env,
  commit,
  sessionReplay = false,
  sessionReplaySampleRate = 0,
  defaultPrivacyLevel = DefaultPrivacyLevel.MASK_USER_INPUT,
}: InitProps) => {
  // It is safe to call this method multiple times - Datadog takes care of it
  // and only initialises the RUM collector once (thanks to silentMultipleInit flag)
  datadogRum.init({
    applicationId,
    clientToken,
    site: site || 'datadoghq.eu',
    service,
    env: `nx-${env}`,
    version: commit,
    silentMultipleInit: true,
    trackSessionAcrossSubdomains: true,
    allowedTracingUrls: [
      'https://smarty.co.uk',
      'https://www.smarty.co.uk',
      /https:\/\/.*\.smarty\.3\.uk/,
    ],
    sessionSampleRate: 100,
    sessionReplaySampleRate,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel,
    beforeSend: (event) => {
      // The below condition unifies views by modifying the collected URLs and storing them as paths
      if (event.view && event.view.url) {
        const url = new URL(event.view.url)
        event.view['url_host'] = url.host
        event.view.url = url.pathname
      }
      // The below condition checks if the error message matches the minified react error then ignores it
      if (event.type === 'error' && event.error) {
        if (
          event.error.message &&
          event.error.message.includes('Minified React error')
        ) {
          return false
        }
      }
      if (
        event.view.url.includes('data-addons') ||
        event.view.url.includes('cash-addons')
      ) {
        if (
          event.type === 'action' &&
          event.action.type === 'click' &&
          event.action.frustration?.type.includes('rage_click')
        ) {
          return false
        }
      }
      return true
    },
  })
  if (sessionReplay && sessionReplaySampleRate > 0) {
    datadogRum.startSessionReplayRecording()
  }
}

export const logErrorAndNotify = (
  message: string,
  error?: unknown,
  context?: object
) => {
  console.error(message, error, context)

  datadogRum.addError(error, context)
}

export const setDatadogRumUser = (ref: string) => {
  datadogRum.setUser({ id: ref })
}

export const clearDatadogRumUser = () => {
  datadogRum.clearUser()
}
